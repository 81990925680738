export const screenScale = (container, screen, heightScale=true) => {
    if (!container || !screen) return;
    const width = screen.clientWidth;
    const height = screen.clientHeight;
    let currentwh = { width, height };
    currentwh.width = container?.clientWidth || width;
    currentwh.height = container?.clientHeight || height;
    let ww = currentwh.width/width;
    let hh = currentwh.height/height;
    let scale = ww < hh ? ww : hh;
    if (!heightScale) {
      scale = ww;
    }
    screen.style.zoom  = scale ;
    
  
  }