<template>
  <div :class="mode?'container':'containerPro'" :style="`border: 2px solid ${color}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["color", "children","mode"],
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  cursor: pointer;
  width: 110px;
  height: 32px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 10px 6px 6px;
}
.containerPro {
  cursor: pointer;
  width: 420px;
  height: 100px;
  border-radius: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px 6px 6px;
  font-size: 64px;
  border-width: 5px !important;
}
</style>
