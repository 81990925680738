import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    windowSize: "large", // small, medium, large
    userInfo: {},
  },
  getters: {
    getWindowSize(state) {
      return state.windowSize;
    },
  },
  mutations: {
    SET_WINDOW_SIZE(state, width) {
      state.windowSize = width;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    updateWindowSize({ commit }, width) {
      commit("SET_WINDOW_SIZE", width);
    },
    updateUserInfo({ commit }, userInfo) {
      commit("SET_USER_INFO", userInfo);
    },
  },
  modules: {},
});
