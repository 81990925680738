<template>
    <div class="inner-container">
      <div class="nav-bar">
        <div
          class="logo"
          @click="
            () => {
              goTo('/');
              act = '';
            }
          "
          style="cursor: pointer"
        >
          <img src="@/assets/svg/logo.svg" />
        </div>
        <div class="nav">
          <!-- 公司简介 -->
          <div
            :class="`navItem ${act === 'aboutUs' ? 'act' : null}`"
            @click="
              () => {
                goTo('/aboutUs');
                act = 'aboutUs';
              }
            "
          >
            회사소개
          </div>
          <!-- 公告事项 -->
          <div
            :class="`navItem ${act === 'notice' ? 'act' : null}`"
            @click="
              () => {
                goTo('/notice');
                act = 'notice';
              }
            "
          >
            공지사항
          </div>
          <!-- 女性锦集 -->
          <div
            :class="`navItem ${act === 'album' ? 'act' : null}`"
            @click="
              () => {
                goTo('/album');
                act = 'album';
              }
            "
          >
            여성앨범
          </div>
          <!-- 论谈 -->
          <div
            :class="`navItem ${act === 'charge' ? 'act' : null}`"
            @click="
              () => {
                goTo('/charge');
                act = 'charge';
              }
            "
          >
            이용절차
          </div>
          <!-- 使用手册 -->
          <div
            :class="`navItem ${act === 'process' ? 'act' : null}`"
            @click="
              () => {
                goTo('/process');
                act = 'process';
              }
            "
          >
            이용후기
          </div>
          <!-- 联系合作伙伴 -->
          <div
            :class="`navItem ${act === 'contactUs' ? 'act' : null}`"
            @click="
              () => {
                goTo('/contactUs');
                act = 'contactUs';
              }
            "
          >
            제휴문의
          </div>
        </div>
        <div class="contact">
          <div class="v_center">
            <div>
              <div class="server-container">
                <img src="@/assets/svg/contact.svg" />
                <span class="server">문의카톡</span>
              </div>
              <img class="call" src="@/assets/img/n6666.png" />
            </div>
          </div>
          <span class="tip"> 언제든지 문의주세요친절하게 모시겠습니다 </span>
        </div>
        <div class="icons">
          <div class="icons-item">
            <img src="@/assets/svg/home.svg" alt="" />
            <span class="icon-text">홈으로</span>
          </div>
          <div class="icons-item">
            <img src="@/assets/svg/key.svg" alt="" />
            <span class="icon-text">정보수정</span>
          </div>
          <div class="icons-item" @click="() => goTo('/withdrawUser')">
            <img src="@/assets/svg/cash_out.svg" alt="" />
            <span class="icon-text">충전목록</span>
          </div>
          <div class="icons-item" @click="() => goTo('/chargeUser')">
            <img src="@/assets/svg/charge.svg" alt="" />
            <span class="icon-text">출금목록</span>
          </div>
        </div>
        <UseInfo :data="userInfo"></UseInfo>
      </div>
      <div class="topViewContainer">
        <div style="width: 372px">
          <img class="girl" width="372px" src="@/assets/img/banner/group3.png" />
        </div>
        <div class="font-container">
          <div class="large">최그의 에이스들만 모였다！</div>
          <div class="large">저희 삽에서만 느낄수 있는 에이 스들과의 만남!</div>
          <div class="small">완벽한 만족을 위한 노력</div>
          <div class="small">Night's efforts for perfect satisfaction</div>
        </div>
        <div style="width: 372px">
          <img class="girl" width="372px" src="@/assets/img/banner/group4.png" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import UseInfo from "./useInfo/userInfo.vue";
  export default {
    components: {
      UseInfo,
    },
    data() {
      return {
        act: "",
      };
    },
    methods: {
      goTo(path) {
        console.log(path);
        if (path !== this.$router.currentRoute.path) {
          this.$router.push(path);
        }
      },
    },
    computed: {
      userInfo() {
        return this.$store.state.userInfo;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .nav-bar {
    display: flex;
    height: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    .logo {
      margin-left: 169px;
    }
    .nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .navItem {
        font-size: 16px;
        height: 100%;
        font-weight: 600;
        margin-right: 18px;
        cursor: pointer;
        &:last-child() {
          margin-right: 0;
        }
      }
      .act {
        color: rgba(245, 2, 58, 1);
      }
    }
    .contact {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .v_center {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .server-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .server {
            font-size: 10px;
          }
          .call {
            width: 63px;
            height: 24px;
          }
        }
      }
  
      .tip {
        color: rgba(142, 144, 148, 1);
        font-size: 12px;
        display: inline-block;
        width: 134px;
      }
    }
    .icons {
      width: 156px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 16px;
      margin-right: 61px;
      .icons-item {
        display: flex;
        align-items: center;
        justify-items: center;
        width: 70px;
        cursor: pointer;
  
        .icon-text {
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
  .topViewContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 50px;
    background-image: url("@/assets/img/banner/group1.png");
    background-position: -250px -310px;
    background-size: 1200px 900px;
    background-repeat: no-repeat;
    .font-container {
      font-family: "Arita Dotum";
      font-size: 48px;
      font-weight: 700;
      padding-top: 64px;
      text-align: center;
      .large {
        &:nth-child(n + 1) {
          margin-top: 32px;
        }
      }
      .small {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        font-size: 24px;
        margin-top: 32px;
        &:nth-child(n + 1) {
          margin-top: 12px;
        }
      }
    }
    .girl {
      flex: 0 0 auto;
      margin-top: 64px;
    }
  }
  .inner-container {
    min-width: 1200px;
  }
  </style>
  