<template>
  <div
    ref="containerRef"
    :class="oldWindowSize == 'small' ? 'mobile_container' : 'pc_container'"
  >
    <headerPage></headerPage>
    <transition mode="out-in" name="fade">
      <router-view />
    </transition>
    <footerPage></footerPage>
  </div>
</template>
<script>
import headerPage from "./components/header/headerPage.vue";
import footerPage from "./components/footer/index.vue";
import { screenScale } from "./assets/screenResize";
export default {
  components: { headerPage, footerPage },
  data() {
    return {};
  },
  methods: {
    handleResize() {
      const newWidth = window.innerWidth;
      const newWindowSize = newWidth > 900 ? "large" : "small";
      if (newWindowSize !== this.oldWindowSize) {
        this.$store.dispatch("updateWindowSize", newWindowSize);
      }
      if (newWindowSize == "small") {
        screenScale(document.body, this.$refs.containerRef, false);
      } else {
        this.$refs.containerRef.style.zoom = 0.75;
      }
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    // window.addEventListener("touchmove", (e) => e.preventDefault(), {
    //   passive: false,
    // });
  },
  computed: {
    oldWindowSize() {
      return this.$store.state.windowSize;
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}
body,
html {
    margin: 0;
    overflow-x: hidden;
    touch-action: pan-y; /* 或pan-x pan-y等，根据需要选择 */
    -webkit-overflow-scrolling: touch; /* 适用于 iOS */
    overscroll-behavior: contain; /* 适用于现代浏览器，防止滚动回弹 */
}
.pc_container {
  zoom: 0.75;
  width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;
}

nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
