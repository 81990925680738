<template>
  <div>
    <pcHeader v-if="oldWindowSize === 'large'"></pcHeader>
    <mobileHeaher v-else></mobileHeaher>
  </div>
</template>

<script>
import UseInfo from "./useInfo/userInfo.vue";
import pcHeader from "./pcHeader.vue"
import mobileHeaher from './mobileHeader.vue'
export default {
  components: {
    UseInfo,
    pcHeader,
    mobileHeaher
  },
  data() {
    return {
      act: "",
    };
  },
  methods: {
    goTo(path) {
      if (path !== this.$router.currentRoute.path) {
        this.$router.push(path);
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    oldWindowSize() {
      return this.$store.state.windowSize;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
