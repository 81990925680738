<template>
  <div :class="oldWindowSize === 'large'?'container':'containerPro'">
    <template v-if="data.balance">
      <div :class="oldWindowSize === 'large'?'balance mr':'balance mr'">
        포인트 <span class="balance-count">{{ data.balance }}</span
        >p
      </div>
      <customButtom
        :mode="oldWindowSize === 'large'"
        class="mr"
        color="rgba(0, 0, 0, 0.5)"
        @click.native="logout"
      >
        <img src="@/assets/svg/logo_out.svg" alt="" />
        <span :class="oldWindowSize === 'large'?'text':'textPro'" style="color: rgba(0, 0, 0, 0.5)">로그아웃</span>
      </customButtom>
      <customButtom color="rgba(81, 73, 235, 1)"
      :mode="oldWindowSize === 'large'"
      
      >
        <img src="@/assets/svg/pc.svg" alt="" />
        <span  :class="oldWindowSize === 'large'?'text':'textPro'" style="color: rgba(81, 73, 235, 1)">관리하기</span>
      </customButtom>
    </template>
    <template v-else>
      <customButtom
      :mode="oldWindowSize === 'large'"

        color="rgba(81, 73, 235, 1)"
        @click.native="goTo('/login')"
        class="mr"
      >
        <img src="@/assets/svg/logo_in.svg" alt="" />
        <span  :class="oldWindowSize === 'large'?'text':'textPro'" style="color: rgba(81, 73, 235, 1)"
          >로그민미필요합니다</span
        >
      </customButtom>
      <customButtom
      :mode="oldWindowSize === 'large'"

        class="mr"
        color="rgba(245, 2, 58, 1)"
        @click.native="goTo('/register')"
      >
        <img src="@/assets/svg/vip.svg" alt="" />
        <span  :class="oldWindowSize === 'large'?'text':'textPro'" style="color: rgba(245, 2, 58, 1)"
          >회원미마니십니까?</span
        >
      </customButtom>
    </template>
  </div>
</template>

<script>
import customButtom from "./customButton.vue";
export default {
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  components: {
    customButtom,
  },
  methods: {
    goTo(path) {
      if (path !== this.$router.currentRoute.path) {
        this.$router.push(path);
      }
    },
    logout() {
      const userInfo = {};
      this.$store.dispatch("updateUserInfo", userInfo);
      if (
        "/" !== this.$router.currentRoute.path ||
        !this.$router.currentRoute.path
      ) {
        this.$router.push("/");
      }
    },
  },
  computed:{
    oldWindowSize() {
      return this.$store.state.windowSize;
    },
  }
};
</script>

<style scoped lang="scss">
.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.containerPro {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img{
    width: 100px;
  }
}
.balance {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .balance-count {
    color: rgba(245, 2, 58, 1);
  }
  @media screen and (max-width: 900px) {
    zoom:3
  }
}
.balancePro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 50px;

  .balance-count {
    color: rgba(245, 2, 58, 1);
  }
}
.text {
  font-size: 12px;
  line-height: 12px;
  margin-left: 8px;
}
.textPro {
  font-size: 40px;
  line-height: 40px;
  margin-left: 8px;
}
.mr {
  margin-right: 7px;
}
</style>
