var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner-container"},[_c('div',{staticClass:"nav-bar"},[_c('div',{staticClass:"logo",staticStyle:{"cursor":"pointer"},on:{"click":() => {
          _vm.goTo('/');
          _vm.act = '';
        }}},[_c('img',{attrs:{"src":require("@/assets/svg/logo.svg")}})]),_c('div',{staticClass:"nav"},[_c('div',{class:`navItem ${_vm.act === 'aboutUs' ? 'act' : null}`,on:{"click":() => {
            _vm.goTo('/aboutUs');
            _vm.act = 'aboutUs';
          }}},[_vm._v(" 회사소개 ")]),_c('div',{class:`navItem ${_vm.act === 'notice' ? 'act' : null}`,on:{"click":() => {
            _vm.goTo('/notice');
            _vm.act = 'notice';
          }}},[_vm._v(" 공지사항 ")]),_c('div',{class:`navItem ${_vm.act === 'album' ? 'act' : null}`,on:{"click":() => {
            _vm.goTo('/album');
            _vm.act = 'album';
          }}},[_vm._v(" 여성앨범 ")]),_c('div',{class:`navItem ${_vm.act === 'charge' ? 'act' : null}`,on:{"click":() => {
            _vm.goTo('/charge');
            _vm.act = 'charge';
          }}},[_vm._v(" 이용절차 ")]),_c('div',{class:`navItem ${_vm.act === 'process' ? 'act' : null}`,on:{"click":() => {
            _vm.goTo('/process');
            _vm.act = 'process';
          }}},[_vm._v(" 이용후기 ")]),_c('div',{class:`navItem ${_vm.act === 'contactUs' ? 'act' : null}`,on:{"click":() => {
            _vm.goTo('/contactUs');
            _vm.act = 'contactUs';
          }}},[_vm._v(" 제휴문의 ")])]),_vm._m(0),_c('div',{staticClass:"icons"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"icons-item",on:{"click":() => _vm.goTo('/withdrawUser')}},[_c('img',{attrs:{"src":require("@/assets/svg/cash_out.svg"),"alt":""}}),_c('span',{staticClass:"icon-text"},[_vm._v("충전목록")])]),_c('div',{staticClass:"icons-item",on:{"click":() => _vm.goTo('/chargeUser')}},[_c('img',{attrs:{"src":require("@/assets/svg/charge.svg"),"alt":""}}),_c('span',{staticClass:"icon-text"},[_vm._v("출금목록")])])]),_c('UseInfo',{attrs:{"data":_vm.userInfo}})],1),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"v_center"},[_c('div',[_c('div',{staticClass:"server-container"},[_c('img',{attrs:{"src":require("@/assets/svg/contact.svg")}}),_c('span',{staticClass:"server"},[_vm._v("문의카톡")])]),_c('img',{staticClass:"call",attrs:{"src":require("@/assets/img/n6666.png")}})])]),_c('span',{staticClass:"tip"},[_vm._v(" 언제든지 문의주세요친절하게 모시겠습니다 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icons-item"},[_c('img',{attrs:{"src":require("@/assets/svg/home.svg"),"alt":""}}),_c('span',{staticClass:"icon-text"},[_vm._v("홈으로")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icons-item"},[_c('img',{attrs:{"src":require("@/assets/svg/key.svg"),"alt":""}}),_c('span',{staticClass:"icon-text"},[_vm._v("정보수정")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topViewContainer"},[_c('div',{staticStyle:{"width":"372px"}},[_c('img',{staticClass:"girl",attrs:{"width":"372px","src":require("@/assets/img/banner/group3.png")}})]),_c('div',{staticClass:"font-container"},[_c('div',{staticClass:"large"},[_vm._v("최그의 에이스들만 모였다！")]),_c('div',{staticClass:"large"},[_vm._v("저희 삽에서만 느낄수 있는 에이 스들과의 만남!")]),_c('div',{staticClass:"small"},[_vm._v("완벽한 만족을 위한 노력")]),_c('div',{staticClass:"small"},[_vm._v("Night's efforts for perfect satisfaction")])]),_c('div',{staticStyle:{"width":"372px"}},[_c('img',{staticClass:"girl",attrs:{"width":"372px","src":require("@/assets/img/banner/group4.png")}})])])
}]

export { render, staticRenderFns }