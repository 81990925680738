import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/index.vue"),
  },
  {
    path: "/withdrawUser",
    name: "withdrawUser",
    component: () =>
      import(/* webpackChunkName: "withdrawUser" */ "@/views/withdrawUser/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
  },
  {
    path: "/confirmPassword",
    name: "confirmPassword",
    component: () =>
      import(/* webpackChunkName: "confirmPassword" */ "@/views/confirmPassword/index.vue"),
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: () =>
      import(/* webpackChunkName: "userAgreement" */ "@/views/userAgreement/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/register/index.vue"),
  },
  {
    path: "/chargeUser",
    name: "chargeUser",
    component: () =>
      import(/* webpackChunkName: "chargeUser" */ "@/views/chargeUser/index.vue"),
  },
  {
    path: "/details",
    name: "details",
    component: () =>
      import(/* webpackChunkName: "details" */ "@/views/details/details.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () =>
      import(/* webpackChunkName: "aboutUs" */ "@/views/aboutUs/aboutUs.vue"),
  },
  {
    path: "/notice",
    name: "notice",
    component: () =>
      import(/* webpackChunkName: "notice" */ "@/views/notice/notice.vue"),
  },
  {
    path: "/album",
    name: "album",
    component: () =>
      import(/* webpackChunkName: "album" */ "@/views/album/album.vue"),
  },
  {
    path: "/albumDetail",
    name: "albumDetail",
    component: () =>
      import(/* webpackChunkName: "albumDetail" */ "@/views/album-detail/albumDetail.vue"),
  },
  {
    path: "/process",
    name: "process",
    component: () =>
      import(/* webpackChunkName: "process" */ "@/views/process/process.vue"),
  },
  {
    path: "/charge",
    name: "charge",
    component: () =>
      import(/* webpackChunkName: "charge" */ "@/views/charge/charge.vue"),
  },
  {
    path: "/chargeDetail",
    name: "chargeDetail",
    component: () =>
      import(/* webpackChunkName: "chargeDetail" */ "@/views/chargeDetail/chargeDetail.vue"),
  },
  {
    path: "/review",
    name: "review",
    component: () =>
      import(/* webpackChunkName: "review" */ "@/views/review/review.vue"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "@/views/withdraw/withdraw.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () =>
      import(/* webpackChunkName: "contactUs" */ "@/views/contactUs/index.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () =>
      import(/* webpackChunkName: "403" */ "@/views/_403/index.vue"),
  },
  {
    path: "*",
    name: "404",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/_404/index.vue"),
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
