<template>
  <div class="container">
    <div class="text">
      대표 : 박형식 | 주소 : 서울 금천구 가산디지털1로 145에이스하이엔드타워 3차
      702호
    </div>
    <div class="border"></div>
    <div class="text">Copyright © All rights reserved.</div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: 128px;
  background: rgba(240, 241, 243, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 900px) {
      zoom:2;
    }
  .text {
    height: 16px;
    /** 文本1 */
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
    vertical-align: top;
  }
  .border {
    left: 660px;
    top: 64px;
    width: 600px;
    height: 0px;

    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }
}
</style>
