<template>
    <div class="inner-container">
        <div class="nav-bar">
            <div class="logo" @click="() => {
                    goTo('/');
                    act = '';
                }
                " style="cursor: pointer">
                <img src="@/assets/svg/logo.svg" />
            </div>
            <mobileUserInfo :data="userInfo"></mobileUserInfo>
        </div>
        <div class="nav-container">
            <i class="el-icon-menu icon" @click="drawer = true"></i>
            <div class="nav">
                <!-- 公司简介 -->
                <div :class="`navItem ${act === 'aboutUs' ? 'act' : null}`" @click="() => {
                        goTo('/aboutUs');
                        act = 'aboutUs';
                    }
                    ">
                    회사소개
                </div>
                <!-- 公告事项 -->
                <div :class="`navItem ${act === 'notice' ? 'act' : null}`" @click="() => {
                        goTo('/notice');
                        act = 'notice';
                    }
                    ">
                    공지사항
                </div>
                <!-- 女性锦集 -->
                <div :class="`navItem ${act === 'album' ? 'act' : null}`" @click="() => {
                        goTo('/album');
                        act = 'album';
                    }
                    ">
                    여성앨범
                </div>
                <!-- 论谈 -->
                <div :class="`navItem ${act === 'charge' ? 'act' : null}`" @click="() => {
                        goTo('/charge');
                        act = 'charge';
                    }
                    ">
                    이용절차
                </div>
                <!-- 使用手册 -->
                <div :class="`navItem ${act === 'process' ? 'act' : null}`" @click="() => {
                        goTo('/process');
                        act = 'process';
                    }
                    ">
                    이용후기
                </div>
                <!-- 联系合作伙伴 -->
                <div :class="`navItem ${act === 'contactUs' ? 'act' : null}`" @click="() => {
                        goTo('/contactUs');
                        act = 'contactUs';
                    }
                    ">
                    제휴문의
                </div>
            </div>
        </div>
        <div class="topViewContainer">
            <div style="width: 372px">
                <img class="girl" width="372px" src="@/assets/img/banner/group3.png" />
            </div>
            <div class="font-container">
                <div class="large">최그의 에이스들만 모였다！</div>
                <div class="large">저희 삽에서만 느낄수 있는 에이 스들과의 만남!</div>
                <div class="small">완벽한 만족을 위한 노력</div>
                <div class="small">Night's efforts for perfect satisfaction</div>
            </div>
            <div style="width: 372px">
                <img class="girl" width="372px" src="@/assets/img/banner/group4.png" />
            </div>
        </div>
        <el-drawer
            size="750px"
            title=""
            direction="ltr"
            :visible.sync="drawer"
            :with-header="false">
            <div class="contact">
                <img class="call" src="@/assets/img/n6666.png" />
                <span class="tip"> 언제든지 문의주세요친절하게 모시겠습니다 </span>
            </div>
            <div class="icons">
                <div class="icons-item">
                    <img src="@/assets/svg/home.svg" alt="" />
                    <span class="icon-text">홈으로</span>
                </div>
                <div class="icons-item">
                    <img src="@/assets/svg/key.svg" alt="" />
                    <span class="icon-text">정보수정</span>
                </div>
                <div class="icons-item" @click="() =>{ 
                    this.drawer = false
                    logout()
                    }">
                    <img src="@/assets/svg/logo_out.svg" alt="" />
                    <span class="icon-text">로그아웃</span>
                </div>
                <div class="icons-item" @click="() =>{
                    this.drawer = false
                    goTo('/chargeUser')}">
                    <img src="@/assets/svg/pc.svg" alt="" />
                    <span class="icon-text">관리하기</span>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import mobileUserInfo from "./mobileUserInfo/userInfo.vue";
export default {
    components: {
        mobileUserInfo,
    },
    data() {
        return {
            act: "",
            drawer:false
        };
    },
    methods: {
        goTo(path) {
            if (path !== this.$router.currentRoute.path) {
                this.$router.push(path);
            }
        },
        logout() {
            const userInfo = {};
            this.$store.dispatch("updateUserInfo", userInfo);
            if (
                "/" !== this.$router.currentRoute.path ||
                !this.$router.currentRoute.path
            ) {
                this.$router.push("/");
            }
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-bar {
    display: flex;
    height: 180px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .logo {
        img{
            width: 180px;
            // height: 100px;
        }
    }

}

.topViewContainer {
    zoom: 1.5;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 50px;
    background-image: url("@/assets/img/banner/group1.png");
    background-position: -250px -310px;
    background-size: 1200px 900px;
    background-repeat: no-repeat;

    .font-container {
        font-family: "Arita Dotum";
        font-size: 48px;
        font-weight: 700;
        padding-top: 64px;
        text-align: center;

        .large {
            &:nth-child(n + 1) {
                margin-top: 32px;
            }
        }

        .small {
            zoom: 1.5;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 500;
            font-size: 24px;
            margin-top: 32px;

            &:nth-child(n + 1) {
                margin-top: 12px;
            }
        }
    }

    .girl {
        flex: 0 0 auto;
        margin-top: 64px;
    }
}

.inner-container {
    min-width: 1200px;
}
.nav-container {
    display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: baseline;

}
    .icon {
        margin-left:50px;
        margin-right:50px;
        font-size: 100px;
        padding-top: 30px;
    }
.nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .navItem {
            font-size: 60px;
            height: 70px;
            font-weight: 600;
            margin-right: 30px;
            cursor: pointer;
            &:last-child() {
                margin-right: 0;
            }
            @media screen and (max-width: 500px) {
                font-size: 60px;
                height: 70px;
            }
            @media screen and (max-width: 900px) {
                font-size: 60px;
                height: 90px;
            }
        }
        .act {
            color: rgba(245, 2, 58, 1);
        }
    }

    .contact {
        height: 250px;
        display: flex;
        align-items: left;
        justify-content: center;
        flex-direction: column;
        padding: 50px;
        img{
            width: 300px;
        }

        .tip {
            color: rgba(142, 144, 148, 1);
            font-size: 26px;
            display: inline-block;
            width: 100%;
        }
    }
    .server-container {
                display: flex;
                justify-content: center;
                align-items: center;
                .img{
                    width: 100px;
                    height: 100px
                }
                .server {
                    font-size: 10px;
                }

            }
    .icons {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 16px;
        margin-right: 61px;

        .icons-item {
            display: flex;
            align-items: center;
            justify-items: center;
            width: 100%;
            height: 120px;
            cursor: pointer;

            .icon-text {
                font-size: 52px;
                font-weight: 500;
                line-height: 12px;
                color: rgba(0, 0, 0, 1);
            }
            img{
                margin: 0 80px;
                width: 80px;
            }
        }
    }
</style>